import React from 'react';
import { FormattedMessage } from 'react-intl';

import Layout from '../../components/Layout';
import useLocale from '../../hooks/useLocale';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const TermsAndConditions = () => {
  const { enLocale } = useLocale({ matchPathName: '/es/' });

  return (
    <Layout title={{ id: 'terms.seo.title' }} description={{ id: 'terms.seo.description' }}>
      <div className="terms-and-privacy-page">
        <div className="container container--wide-padding">
          <div className="wrapper">
            <h1 className="c-title-42">
              <FormattedMessage id="terms.sec1.title" />
            </h1>
            <h2 className="subsection__title">
              <FormattedMessage id="terms.sub1.title" />
            </h2>
            <ol className="subsection">
              <h2 className="subsection__title subsection__title--sm">
                <FormattedMessage id="terms.sub1.text1" />
              </h2>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text2" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text3" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text4" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text5" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text6" />
                <ol>
                  <li>
                    <FormattedMessage id="terms.sub1.text7" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.sub1.text8" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.sub1.text9" />
                  </li>
                  <li>
                    <FormattedMessage id="terms.sub1.text10" />
                  </li>
                </ol>
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text11" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text12" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text13" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text14" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text15" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text16" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub1.text17" />
              </li>
            </ol>

            <h2 className="subsection__title">
              <FormattedMessage id="terms.sub2.title" />
            </h2>
            <ol className="subsection">
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text1" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text2" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text3" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text4" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text5" />
              </li>
              {/* <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text6" />
              </li> */}
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text7" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text8" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text9" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text10" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text11" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text12" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text13" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text14" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text15" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text16" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub2.text17" />
              </li>
              {/* <li className="subsection__par">
                If the number of students in a group is reduced to less than 4, TEFL Iberia reserves
                the right to make adjustments (reset time, reduced hours, integrate into another
                group).
              </li>
              <li className="subsection__par">
                The school can substitute teachers for these classes as they see fit.
              </li> */}
            </ol>

            <h2 className="subsection__title">
              <FormattedMessage id="terms.sub3.title" />
            </h2>
            <ol className="subsection">
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text1" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text2" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text3" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text4" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text5" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text6" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text7" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text8" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text9" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text10" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text11" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text12" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text13" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text14" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text15" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub3.text16" />
              </li>
              {!enLocale && (
                <li className="subsection__par">
                  <FormattedMessage id="terms.sub3.text17" />
                </li>
              )}
            </ol>

            <h2 className="subsection__title">
              <FormattedMessage id="terms.sub4.title" />
            </h2>
            <ol className="subsection">
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text1" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text2" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text3" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text4" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text5" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text6" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text7" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text8" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text9" />
              </li>
              <li className="subsection__par">
                <FormattedMessage id="terms.sub4.text10" />
              </li>
            </ol>
            <h2 className="subsection__title">
              <FormattedMessage id="terms.sub5.title" />
            </h2>
            <ul className="subsection__list">
              <li className="item">
                <FormattedMessage id="terms.sub5.text1" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text2" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text3" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text4" />
              </li>

              <li className="item">
                <FormattedMessage id="terms.sub5.text5" />
              </li>
              <li className="item">
                <FormattedMessage id="terms.sub5.text6" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
